<template>
  <div class="notFound">
    <h2>We could not find the page you are looking for!</h2>
    <div>
      <button id="btn" type="button" class="submit" @click="blogs()" size="md">
        Check out our blogs
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    blogId: String,
  },
  created() {},
  data() {
    return {};
  },
  methods: {
    blogs() {
      this.$router
        .push({
          name: "Blogs",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.notFound {
  padding-top: 100px;
  padding-bottom: 500px;
}
.submit {
  display: inline-block;
  padding: 12px 24px;
  background-color: #376996;
  border: 1px solid #376996;
  border-radius: 4px;
  transition: all 0.8s ease-in;
  position: relative;
  overflow: hidden;
  font-size: 0.85em;
  color: #ffff;
  z-index: 1;
}

.submit:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.submit:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #1f487e;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.submit:hover {
  color: #ffffff;
  border: 1px solid #1f487e;
}

.submit:hover:before {
  top: -35%;
  background-color: #376996;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.submit:hover:after {
  top: -45%;
  background-color: #1f487e;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}
</style>
